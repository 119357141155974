@import 'theme';

.funding-bar-container {
  width: 100%;

  &.disabled {
    filter: grayscale(1);
    opacity: 0.5;
  }
}

.funding-title {
  color: $color1;
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 0.2em;
}

.funding-bar {
  height: 2em;
  width: 100%;
  border-radius: 1em;
  overflow: hidden;
  //background-color: darken(white, 10);
  box-shadow: inset 0 0 1em rgba(0, 0, 0, 0.1);

  & .progress {
    position: relative;
    height: 100%;
    width: 50%;
    background: linear-gradient(90deg, $color2, $color1);
  }
}

.funding-progress {
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}