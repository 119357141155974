@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&family=Nunito:ital,wght@0,300;0,400;0,700;1,400&display=swap');
@import 'styles/theme';

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: 'Nunito', 'Roboto', 'Oxygen', sans-serif;
  background-color: $color1;
  background-image: linear-gradient(62deg, $color1 0%, $color2 100%);
  font-size: 17px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  width: 100%;
  height: 100%;
}