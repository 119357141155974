@import 'theme';

.App {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.window {
  width: 50em;
  max-width: 100vw;
  background-color: white;
  border-radius: 1em;
  flex-shrink: 0;
  box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  height: 90vh;
  overflow: hidden;

  .window-inner {
    max-height: 100%;
    overflow-y: auto;
    padding: 3em;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0.36rem;
      height: 0.36rem;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0.18rem;
      -webkit-box-shadow: inset 0 0 0.36rem rgba(0, 0, 0, .3);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.18rem;
      background-color: $color1;
    }
  }
}

.title {
  color: $color1;
  font-size: 3em;
  text-transform: none;
  margin: 0;
}

.funding-message {
  font-size: 1.2em;
  font-weight: 300;
  text-align: left;
}

.copyright {
  position: absolute;
  bottom: 0;
  color: white;
}